/* This example requires Tailwind CSS v2.0+ */
import * as React from "react"
import {
    PhotographIcon,
    GlobeAltIcon,
    LocationMarkerIcon,
    ColorSwatchIcon,
    ArrowsExpandIcon,
    DeviceMobileIcon
  } from '@heroicons/react/outline'

  const features = [
    { name: '360° rundum Fotos', icon: PhotographIcon, text: 'Ob für Facebook oder deine eigene Webseite. Unsere 360° Fotos fallen auf, garantiert.' },
    { name: 'Virtuelle Rundgänge', icon: GlobeAltIcon, text: 'Erlaube deinen Kunden einen tiefen Einblick in deine Location und hinter die Kulissen.' },
    { name: 'Google Maps Touren', icon: LocationMarkerIcon, text: 'Auch bei Google Maps fällst du mit 360° Fotos und virtuellen Rundgängen positiv auf.' },
    { name: 'High Dynamic Range Bilder', icon: ColorSwatchIcon, text: 'Für brilliante und satte Farben auch bei schwierigen Lichtverhältnissen.' },
    { name: 'Gigapixel Scale', icon: ArrowsExpandIcon, text: 'Damit auch das kleinste Detail nicht verloren geht erstellen wir detailreiche Gigapixel Aufnahmen.' },
    { name: 'Desktop oder Smartphone', icon: DeviceMobileIcon, text: 'Alle Rundgänge können auf dem Desktop oder mobil abgespielt werden. Auch die Installation als Webapp ist möglich.' },
  ]

  export default function Features() {
    return (
      <div className="relative bg-white py-8 sm:py-12 lg:py-16">
        <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
          <h2 className="text-base font-semibold tracking-wider text-indigo-600 uppercase">Jung, frisch, dynamisch, einzigartig, cool</h2>
          <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
            Alles was du für erfolgreiche Werbung brauchst
          </p>
          {/* <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
            Phasellus lorem quam molestie id quisque diam aenean nulla in. Accumsan in quis quis nunc, ullamcorper
            malesuada. Eleifend condimentum id viverra nulla.
          </p> */}
          <div className="mt-12">
            <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
              {features.map((feature) => (
                <div key={feature.name} className="pt-6">
                  <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                    <div className="-mt-6">
                      <div>
                        <span className="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg">
                          <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                        </span>
                      </div>
                      <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">{feature.name}</h3>
                      <p className="mt-5 text-base text-gray-500">
                      {feature.text}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
