import * as React from "react"
/* This example requires Tailwind CSS v2.0+ */

import Layout from "../components/layout"
import Logo from "../components/home/logo"
import Referenzen from '../components/home/referenzen'
import Website from '../components/home/website'
import Features from '../components/home/features'
import Cta from '../components/home/cta'
import { Helmet } from 'react-helmet'
import { getImage, getSrc } from 'gatsby-plugin-image';
import {Seo} from "../components/index"
import {useStaticQuery, graphql} from "gatsby"
import Referenz from '../components/general/referenz'

export default function Index({data}) {
    const seoImage = data.myImage
  return (

    <Layout>
        <Seo
        title="360° Panoramen und virtuelle Rundgänge - Wuppertal360.app"


        />

        <Features />
        {data.referenz.nodes.map((item, index) => (
                  <Referenz key={item.id} item={item} refId={item.id} index={index} className="font-medium text-gray-500 hover:text-gray-900" />

                ))}

        <Website />
        <Logo />
        <Cta />
    </Layout>

  )
}



export const indesQuery = graphql`
query {

referenz: allContentfulReferenz(
    sort: {fields: contentful_id, order: ASC}
    filter: {featured: {eq: true}}
) {
    nodes {
    body {
        body
    }
    featured
    id
    image
    link
    signature
    title
    review {
        review
    }
    }
}

myImage: file(relativePath: { eq: "screenshot.png" }) {
childImageSharp {
  gatsbyImageData(layout: CONSTRAINED)
}
}
}
`
