/* This example requires Tailwind CSS v2.0+ */
import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

export default function Example() {
    return (
      <div className="relative bg-gray-50 pt-16 pb-16 overflow-hidden sm:pt-24 lg:pt-32">
        <div className="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl">
          <div>
            <h2 className="text-base font-semibold tracking-wider text-indigo-600 uppercase">Das Komplettpaket für deinen Erfolg</h2>
            <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
              Keine Webseite? Kein Problem.
            </p>
            <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
              Wir gestalten deinen virtuellen Rundgang als Webseite. Neuste Technik, modernes Design. Alles aus einer Hand.
            </p>
          </div>
          <div className="mt-12">
              <a href="https://einfachkreativ-wuppertal.de" target="_blank" rel="noopener">
            <StaticImage
            placeholder="blurred"
              className="rounded-lg shadow-xl ring-1 ring-black ring-opacity-5"
              src="../../assets/tours/screenshot-einfachkreativ.jpg"
              alt="Screenshot von EinFach Kreativ"
            />
            </a>
          </div>
        </div>
      </div>
    )
  }
