/* This example requires Tailwind CSS v2.0+ */
import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
/* This example requires Tailwind CSS v2.0+ */

export default function Logos() {
    return (
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <p className="text-center text-base font-semibold uppercase text-gray-600 tracking-wider">
            Vertrauen von Unternehmen jeglicher Art
          </p>
          <div className="mt-6 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-8">
            <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                <a href="https://s48.wuppertal360.app" target="_blank" rel="noreferrer">
              <StaticImage
                className="max-h-12"
                src="../../assets/logos/logo-s48.png"
                objectFit="contain"
                placeholder="blurred"
                alt="S48 - Stadtteiltreff Langerfeld"
              />
              </a>
            </div>
            <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
            <a href="https://langerfelder-automarkt.wuppertal360.app" target="_blank" rel="noopener">
              <StaticImage className="max-h-12" src="../../assets/logos/logo-automarkt.png" objectFit="contain"
                placeholder="blurred" alt="Langerfelder Automarkt" />
                </a>
            </div>
            <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
            <a href="https://einfachkreativ-wuppertal.de" target="_blank" rel="noopener">
              <StaticImage className="max-h-12" src="../../assets/logos/logo-einfach-kreativ.png" objectFit="contain"
                placeholder="blurred" alt="EinFach Kreativ - Stoff und Kreativ Laden" />
                </a>
            </div>
            <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
            <a href="https://sip-sen.wuppertal360.app" target="_blank" rel="noopener">
              <StaticImage className="max-h-12" src="../../assets/logos/logo-sip-sen.png" objectFit="contain"
                placeholder="blurred" alt="Sip Sen" />
                </a>
            </div>
            <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
            <a href="https://blutspendezentrum-wuppertal.wuppertal360.app" target="_blank" rel="noopener">
              <StaticImage className="max-h-12" src="../../assets/logos/logo-bzw.svg" objectFit="contain"
                placeholder="blurred" alt="Blutspendezentrum Wuppertal" />
                </a>
            </div>
            <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
            <a href="https://landhaus-bilstein.wuppertal360.app" target="_blank" rel="noopener">
              <StaticImage className="max-h-12" src="../../assets/logos/logo-landhaus-bilstein.png" objectFit="contain"
                placeholder="blurred" alt="LandUNTER Haus Bilstein" />
                </a>
            </div>
            <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
            <a href="https://anno.wuppertal360.app" target="_blank" rel="noopener">
              <StaticImage className="max-h-12" src="../../assets/logos/logo-anno.png" objectFit="contain"
                placeholder="blurred" alt="Anno - die Nostalgiekneipe" />
                </a>
            </div>
            <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
            <a href="https://pino.wuppertal360.app" target="_blank" rel="noopener">
              <StaticImage className="max-h-12" src="../../assets/logos/logo-pino.png" objectFit="contain"
                placeholder="blurred" alt="Pino Vinditti - Friseur am Markt" />
                </a>
            </div>
            <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
            <a href="https://croque7.wuppertal360.app" target="_blank" rel="noopener">
              <StaticImage className="max-h-12" src="../../assets/logos/logo-jaworski.png" objectFit="contain"
                placeholder="blurred" alt="Jaworski Croque7 und catering" />
                </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
