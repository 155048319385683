/* This example requires Tailwind CSS v2.0+ */
import * as React from "react"
import { Link } from 'gatsby'

export default function Cta() {
    return (
      <div className="bg-white">
        <div className="max-w-7xl mx-auto text-center py-6 px-4 sm:px-6 lg:py-8 lg:px-8">
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            <span className="block">Bereit für die moderne Zukunft?</span>
            <span className="block">Kontaktiere uns noch heute!</span>
          </h2>
          <div className="mt-8 flex justify-center">
            <div className="inline-flex rounded-md shadow">
              <Link
                to="/kontakt"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
              >
                Kontakt
              </Link>
            </div>
            <div className="ml-3 inline-flex">
              <Link
                to="/referenzen"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200"
              >
                Referenzen
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
