import React from "react"
import {Helmet} from "react-helmet"
import {StaticQuery, graphql} from "gatsby"
import { getImage, getSrc } from 'gatsby-plugin-image';

export const Seo = ({description, keywords, title, image, siteUrl, author}) => {

    return (
        <StaticQuery
        query={detailsQuery}
        render={data => {
            const img = getImage(data.myImage);
            const imgSrc = data.site.siteMetadata.siteUrl + getSrc(img);
            const metaDescription = description || data.site.siteMetadata.description
            const metaTitle = title || data.site.siteMetadata.title
            const metaAuthor = author || data.site.siteMetadata.author
            const metaUrl = siteUrl || data.site.siteMetadata.siteUrl
            const metaImage = image ||  imgSrc
            const metaKeywords = keywords || ["Wuppertal360", "360° Fotos", "Virtuelle Rundgänge"]
            return (
                <Helmet
                title = {title}
                meta={[
                    {
                        name: 'description',
                        content: metaDescription,
                    },
                    {
                        property: 'og:title',
                        content: metaTitle,
                    },
                    {
                        property: 'og:description',
                        content: metaDescription,
                    },
                    {
                        property: 'og:type',
                        content: 'website',
                    },
                    {
                        property: 'og:image',
                        content: metaImage,
                        itemprop: "image",
                    },
                    {
                        property: 'og:url',
                        content: metaUrl,
                    },
                    {
                        property: 'og:updated_time',
                        content: new Date().getTime()
                    },
                    {
                        name: 'twitter:card',
                        content: 'summary_large_image',
                    },
                    {
                        name: 'twitter:creator',
                        content: metaAuthor,
                    },
                    {
                        name: 'twitter:title',
                        content: metaTitle,
                    },
                    {
                        name: 'twitter:description',
                        content: metaDescription,
                    },
                    {
                        name: 'twitter:image',
                        content: metaImage,
                    },
                ].concat(
                    metaKeywords && metaKeywords.length > 0 ? {
                        name: 'keywords',
                        content: metaKeywords.join(', ')
                    } : []
                )}
                />

            )
        }}
        />
    )
}


const detailsQuery = graphql`
query DefaultSEOQuery {
    site{
        siteMetadata{
            title
            description
            author
            image
            siteUrl
        }
    }
    myImage: file(relativePath: { eq: "screenshot.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
}
`
